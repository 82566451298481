import {createSelector} from "@ngrx/store";
import {selectEnveloppeState} from "../../../core/core.state";
import {EnveloppeState} from "./enveloppe.models";

export const selectEnveloppe = createSelector(
    selectEnveloppeState,
    (state: EnveloppeState) => state.enveloppeInfos?.enveloppe
);

export const selectEnveloppeInfosLoading = createSelector(
    selectEnveloppeState,
    state => state.loadingInfosEnveloppe
);

export const selectMaintenanceEnveloppe = createSelector(
    selectEnveloppeState,
    state => state.maintenanceEnveloppe
);

export const selectSessionConflict = createSelector(
    selectEnveloppeState,
    state => state.sessionConflict
);

export const selectShowModalDestinatairePasSignataire = createSelector(
    selectEnveloppeState,
    state => state.showModalDestinataireNonSignataire
);

export const selectJustSigned = createSelector(
    selectEnveloppeState,
    state => state.justSigned
);

export const selectEtapeOtp = createSelector(
    selectEnveloppeState,
    state => state.etapeOtp
);

export const selectSessionReshing = createSelector(
    selectEnveloppeState,
    state => state.sessionRefreshing
);

export const selectEnveloppeFichiers = createSelector(
    selectEnveloppeState,
    state => state.enveloppeInfos?.fichiers
);
export const selectEnveloppePieceJointe = createSelector(
    selectEnveloppeState,
    state => state.enveloppeInfos?.piecesJointes
);
export const selectEnveloppeSession = createSelector(
    selectEnveloppeState,
    state => state.enveloppeInfos?.session
);
export const selectEnveloppeLectureAc = createSelector(
    selectEnveloppeState,
    state => state.enveloppeInfos?.lecture_seule_ac
);
export const selectEnveloppeInfos = createSelector(
    selectEnveloppeState,
    state => state.enveloppeInfos
);
export const selectEnveloppeFichiersANotifier = createSelector(
    selectEnveloppeState,
    state => state.enveloppeInfos?.fichiersANotifier
);
export const selectEnveloppeEtape = createSelector(
    selectEnveloppeState,
    state => state.enveloppeInfos?.etape
);

export const selectEnveloppeInfosError = createSelector(
    selectEnveloppeState,
    state => state.errorInfosEnveloppe
);
export const selectEnveloppeUid = createSelector(
    selectEnveloppeState,
    state => state.uid
);
export const selectIsMobile = createSelector(
    selectEnveloppeState,
    state => state.mobile
);
