import {createAction, props} from "@ngrx/store";
import {AcHTTPResponse} from "../../../core/http/http-response.models";
import {
    EnveloppeEtat,
    EnveloppeFichier,
    EnveloppeMobileToolbar,
    EnveloppeSession,
    InfosEnveloppe
} from "./enveloppe.models";

export const loadEnveloppeAction = createAction(
    '[Enveloppe] Load enveloppe',
    props<{ uid: string }>()
);
export const loadEnveloppeSuccessAction = createAction(
    '[Enveloppe] Load enveloppe success',
    props<AcHTTPResponse<InfosEnveloppe>>()
);
export const loadEnveloppeErrorAction = createAction(
    '[Enveloppe] Load enveloppe error',
    props<{ error: any }>()
);

export const setSessionUid = createAction(
    '[Enveloppe] Set session uid',
    props<{ uid: string }>()
);
export const setSessionConflict = createAction(
    '[Enveloppe] Set setSessionConflict',
    props<{ conflict: boolean }>()
);
export const setIsMobile = createAction(
    '[Enveloppe] Set is mobile',
    props<{ isMobile: boolean }>()
);
export const setEtape = createAction(
    '[Enveloppe] Set etape',
    props<{ etape: number }>()
);
export const setUid = createAction(
    '[Enveloppe] Set uid',
    props<{ uid: string }>()
);
export const setSession = createAction(
    '[Enveloppe] Set session',
    props<EnveloppeSession>()
);
export const setSessionRefreshing = createAction(
    '[Enveloppe] Set session refresh in progress',
    props<{ sessionRefreshing: boolean }>()
);
export const demandeContactEnveloppeExpiree = createAction(
    '[Enveloppe] Demande contact enveloppe expiree',
    props<{ uid: string, comment: string }>()
);
export const demandeContactEnveloppeExpireeSuccess = createAction(
    '[Enveloppe] Demande contact enveloppe expiree success',
    props<any>()
);
export const demandeContactEnveloppeExpireeError = createAction(
    '[Enveloppe] Demande contact enveloppe error',
    props<{ error: any }>()
);
export const pushEnveloppeMobileToolbarState = createAction(
    '[Enveloppe] Push enveloppe mobile toolbar state',
    props<EnveloppeMobileToolbar>()
);
export const popEnveloppeMobileToolbarState = createAction(
    '[Enveloppe] Pop enveloppe mobile toolbar state',
    props<any>()
);

export const updateForms = createAction(
    '[Enveloppe] Update enveloppe forms',
    props<{ uid: string, data: any }>()
);
export const updateFormsSuccess = createAction(
    '[Enveloppe] Update enveloppe forms success',
    props<{ inputData: any }>()
);
export const updateFormsError = createAction(
    '[Enveloppe] Update enveloppe forms error',
    props<any>()
);

export const refreshPdfViewer = createAction(
    '[Enveloppe] Refresh PDF viewer',
);

export const finalValidationError = createAction(
    '[Enveloppe] Final Validation Error',
    props<{ errors: any }>()
);

export const setEtatEnveloppe = createAction(
    '[Enveloppe] Set etat enveloppe',
    props<{ etat: EnveloppeEtat }>()
);

export const updateSignataireEnveloppe = createAction(
    '[Enveloppe] Update signataire enveloppe',
    props<{ signataire: any }>()
);

export const updateSignatairesEnveloppe = createAction(
    '[Enveloppe] Update signataires enveloppe',
    props<{ signataires: any }>()
);
export const setIsSignataire = createAction(
    '[Enveloppe] Set is Signataire',
    props<{ isSignataire: boolean }>()
);
export const setHasSigned = createAction(
    '[Enveloppe] Set has signed',
    props<{ hasSigned: boolean }>()
);

export const setFichierRead = createAction(
    '[Enveloppe] Set fichier read',
    props<{ fichier: EnveloppeFichier, uid: string }>()
);

export const setFichierOpened = createAction(
    '[Enveloppe] Set fichier opened',
    props<{ fichier: EnveloppeFichier, uid: string }>()
);

export const setMaintenanceEnveloppe = createAction(
    '[Enveloppe] Set setMaintenanceEnveloppe',
    props<{ maintenanceEnveloppe: boolean }>()
);

export const setJustSigned = createAction(
    '[Enveloppe] Set setJustSigned',
    props<{ justSigned: boolean }>()
);

export const setEtapeOtp = createAction(
    '[Enveloppe] Set setEtapeOtp',
    props<{ etapeOtp: boolean }>()
);

export const setShowModalDestinataireNonSignataire = createAction(
    '[Enveloppe] Set setShowModalDestinataireNonSignataire',
    props<{ showModalDestinataireNonSignataire: boolean }>()
);
